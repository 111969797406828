export const UPDATE_LOGGED_IN_USER: string = 'UPDATE_LOGGED_IN_USER';
export const LOGOUT: string = 'LOGOUT';
export const BEER_ADDED_TO_INVENTORY: string = 'BEER_ADDED_TO_INVENTORY';
export const BEER_UPDATE_INVENTORY_QUANTITY: string =
  'BEER_UPDATE_INVENTORY_QUANTITY';
export const BEER_REMOVE_FROM_INVENTORY: string = 'BEER_REMOVE_FROM_INVENTORY';
export const SWITCH_THEME_MODE: string = 'SWITCH_THEME_MODE';
export const SHOW_GENERIC_NOTIFICATION: string = 'SHOW_GENERIC_NOTIFICATION';
export const ERROR_NOTIFICATION: string = 'ERROR_NOTIFICATION';
export const TOGGLE_NAVIGATION_MENU: string = 'TOGGLE_NAVIGATION_MENU';
